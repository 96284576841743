
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 25%;
  }
  
  .login-box {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .login-box h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555555;
  }
  
  .input-group input, .input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333333;
  }
  
  .input-group input:focus , .input-group select:hover {
    border-color: #27E9A0;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 102, 255, 0.3);
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #27E9A0;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #012238;
  }
  
  .login-button:active {
    background-color: #012238;
  }

  .register-link {
    margin-top: 15px;
    color: #333333;
  }
  
  .register-link a {
    color: #0066ff;
    text-decoration: none;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }