.admin-users {
    padding: 20px;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .users-table th, .users-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .users-table th {
    background-color: #4CAF50;
    color: white;
  }
  
  .users-table td {
    background-color: #f9f9f9;
  }
  
  .users-table tr:hover {
    background-color: #f1f1f1;
  }
  
  button {
    margin-right: 5px;
    padding: 5px 10px;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  .btnVer {
    background-color: #007BFF;
  }
  
  .btnBloquear {
    background-color: #FFC107;
  }
  
  .btnEliminar {
    background-color: #DC3545;
  }
.user-count {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0; /* color de fondo, puedes ajustarlo */
    font-size: 6em;
    font-weight: bold;
  }
  