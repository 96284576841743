.parte-share-props{
    margin-bottom: 0!important;
}
.refiere-el-sitio img{
    width: 100%;
    margin-bottom: 25px;
}

.cont-into{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    background: #012238;    
}
.cont-into h2{
    position: relative;
    font-size: 14vw;
    color: #012238;
    -webkit-text-stroke: 0.3vw #BFBFBF;
}
.cont-into h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: #BFBFBF;
    text-shadow: 5px 5px 5px white;
    -webkit-text-stroke: 0vw #383d52;
    border-right: 2px solid #BFBFBF;
    overflow: hidden;
    animation: animate 6s linear infinite;
}
@keyframes animate{
    0%,10%,100%{
        width: 0;
    }
    70%,90%{
        width: 100%;
    }
}
.refiere-el-sitio{
    width: 100%;
    min-height: 95vh;
    background-color: #f1f1f1;
    color: #414141;
    padding: 10px;
    box-sizing: border-box;
    max-width: 900px;
    margin: 0 auto;
}
.refiere-el-sitio h2{
    text-align: center;
}
.contenido-refs{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}
.texto-r{
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 6px;
    box-sizing: border-box;
}
.iconos-refs{
    height: 90px;
    width: 260px;
    background-color: white;
    border-radius: 6px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    text-align: center;
    padding-left: 14px;
    box-sizing: border-box;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
                        inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
                        inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
                        inset 0px 1px 3px rgba(0,0,0,0.3);
}

.wrapper-icons {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.wrapper-icons .icon {
    position: relative;
    background: #ffffff;
    border-radius: 50%;
    margin: 3px 6px;
    width: 45px;
    height: 45px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper-icons .icon a{
    color: #3D5056;
}
.lista-premios-ref{
    display: inline-flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
  
.lista-premios-ref li {
    flex: 1 1 300px;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}