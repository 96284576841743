.add-property {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-property h3 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .form-group input[type="file"] {
    padding: 0;
  }
  .suggestions{
    list-style: none;
    text-align: left;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #27E9A0;
    color: #fff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #20c589;
  }

  .mb-3 {
    margin-bottom: 15px;
  }
  
 
  .form-select, .form-control {
    border-radius: 5px;
  }
  
  .btn-success {
    background-color: #28a745;
    border: none;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  
  .form-check-label {
    margin-left: 5px;
  }

  .suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
.conteinerInputCheck{
  display: inline-flex;
  flex-wrap: wrap;
}

.conteinerInputCheck .mb-3{
  margin: 15px;
}
