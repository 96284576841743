.promo-alquileres{
    width: 100%;
    height: 50vh;
    padding: 0;
    margin: 0 auto 55px auto;
    display: inline-flex;
    flex-wrap: wrap;

  }
  .promo-alquileres .texto-promo-alquileres{
    display: grid;
    text-align: center;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    width: 50%;
    height: 40vh;
    margin: 0 auto;
    transition: .3s;
  }
  .texto-promo-alquileres h2{
    font-size: 1.5rem;
    margin: 0;
  }
  .promo-alquileres .img-promo-alquileres{
    width: 50%;
    height: 40vh;
    margin: 0 auto;
    position: relative;
    transition: .3s;
  }
  .promo-alquileres .img-promo-alquileres img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .textos-portada{
    background-color: rgba(0, 0, 0, 0.5);
    padding: 6px;
    box-sizing: border-box;
    border-radius: 4px;
}

  .container-buscador{
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    margin: 0 10px 55px 10px;

}

.rental-properties{
  min-width: 300px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  text-align: center;
  padding-bottom: 50px;
}
.input-group-alq {
  margin-bottom: 20px;
  text-align: left;
  display: grid;
}
.input-group-alq label{
  margin-bottom: 5px;
}
.inputBusAlq{
  max-width: 261px;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 20px;
  font-size: 16px;
  color: #333333;
  -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
  inset 0px 1px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
  box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
}
.rental-carga{
  max-width: 450px;
  position: relative;
}
@media (max-width: 700px) {
  .promo-alquileres .texto-promo-alquileres{
    width: 100%;
  }
  .promo-alquileres .img-promo-alquileres{
    width: 100%;
  }
  .promo-alquileres .texto-promo-alquileres h2{
    font-size: 1.3rem;
  }
}

@media (max-width: 430px) {
  .promo-alquileres .texto-promo-alquileres h2{
    font-size: 1.2rem;
  }
  .container-buscador{
    margin: 0 auto;
  }
}