.rental-property-card {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px 0;
    display: flex;
  }
  
  .rental-property-card img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .property-details {
    flex: 1;
  }
  
  .property-details h3 {
    margin-bottom: 10px;
  }
  
  .property-details p {
    margin-bottom: 5px;
  }
  
  .btn-reserve {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .reserve-modal{
    position: absolute;
    top: 85px;
    left: 1%;
    width: 98%;
    height: 350px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, .5);
    color: white;
    border-radius: 25px;
  }
  .btn-close{
    background: none;
    color: white;
    position: absolute;
    top: 1px;
    right: 5px;
  }
  