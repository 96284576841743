.container-inventario-form {
    background: white;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
  }
 
.smallt {
    display: block;
    margin-bottom: 15px;
    color: #888;
    font-size: 12px;
    text-align: center;
  }
  