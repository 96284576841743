/* VentasPage.css */
.vp-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}

.vp-main-content {
  margin-left: 300px; /* Ajuste para el ancho del MenuAlto */
  transition: margin-left 0.3s ease; /* Animar el cambio de margen en pantallas pequeñas */
}

@media (max-width: 920px) {
  .vp-main-content {
    margin-left: 0; /* No hay margen cuando el MenuAlto está en pantalla completa en pantallas pequeñas */
  }
}
