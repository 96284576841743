.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f8ff;
    color: #4caf50;
    text-align: center;
  }
  
  .success-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .success-container p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .success-container .button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #4caf50;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .success-container .button:hover {
    background-color: #45a049;
  }
  