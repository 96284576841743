.admin-panel {
  padding: 20px;
}
.admin-menu {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}
.menu-item {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
}
.menu-item:hover {
  background-color: #45a049;
}
.submenu {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}
.submenu-item {
  padding: 8px 15px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  transition: background-color 0.3s;
}
.submenu-item:hover {
  background-color: #0056b3;
}


  .inputMolis{
    border-radius: 18px;
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    height: 35px;
    font-size: 19px;
    margin: 0 auto;
    border: 1px solid #E6E5E3;
    box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
  }
  .chart-container {
    width: 100%;
    max-width: 600px; /* Ajusta el tamaño máximo del gráfico */
    height: 400px;
    margin: 45px auto;
  }
  