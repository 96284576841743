.slogan-real-estate{
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    display: grid;
    text-align: center;
    align-items: center;
  }
  .slogan-real-estate .slogan-h1{
    font-size: 1rem;
    background-color: #f7f7f7;
    width: 100%;
    min-height: 250px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    display: grid;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 0px auto 25px auto;
    max-width: 1200px;
  }
  .formFiltroVentas{
    max-width: 600px;
    margin: 0 auto;
  }
  .form-label {
    font-weight: bold;
    color: #333;
  }
  
  .form-select {
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
      inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
      inset 0px 1px 3px rgba(0,0,0,0.3);
  }
  
  .mb-3 {
    margin-bottom: 15px;
  }
  .checkbox-group {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
  }
  .formFiltroVentas p {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .property-filter {
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .filter-textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    resize: none;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
  }
  
  .filter-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #27E9A0;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .filter-button:disabled {
    background-color: #5a9;
    cursor: not-allowed;
  }
  
  .filter-button:hover:not(:disabled) {
    background-color: #5a9;
  }
  
  .loading-message {
    text-align: center;
    font-size: 18px;
    color: #343a40;
  }

  ul{
    padding: 0;
  }
  
  @media (max-width: 768px) {
    .property-filter {
      padding: 15px;
    }
  
    .filter-textarea {
      font-size: 14px;
    }
  
    .filter-button {
      font-size: 16px;
    }
  
    .loading-message {
      font-size: 16px;
    }
  }
  
 
  