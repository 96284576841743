.faq-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
    font-weight: 700;
  }
  
  .faq-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .faq-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .faq-question {
    font-size: 1.5rem;
    color: #27E9A0;
    margin-bottom: 10px;
  }
  
  .faq-answer {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .faq-title {
      font-size: 2rem;
    }
  
    .faq-question {
      font-size: 1.3rem;
    }
  
    .faq-answer {
      font-size: 0.9rem;
    }
  }
  