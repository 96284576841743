.cont-bus-prop {
  border-radius: 3px;
  padding: 3px;
  box-sizing: border-box;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
  background-color: rgb(241, 241, 241);
  max-width: 600px;
  margin: 0 auto 20px auto;
  min-height: 78vh;
  position: relative;
  display: grid;
  align-items: center;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.carousel-inner {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease-in-out;
}

.carousel-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  transform-style: preserve-3d;
}

.carousel-item img {
  width: 100%;
  height: auto;
  border-radius: 3px;
  object-fit: cover;
}

.carousel-item.active {
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 33px;
  border: none;
  z-index: 100;
  color: white;
}

.carousel-control-prev {
  left: 6px;
}

.carousel-control-next {
  right: 6px;
}

.titulo-propiedad {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
  max-width: 90%;
}

.titulo-propiedad h2 {
  font-size: 1.1rem;
  margin-top: 0;
}

.botones-prop {
  position: absolute;
  left: 15px;
  bottom: 36px;
  z-index: 1000;
  height: 50px;
}

.botones-prop ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.botones-prop ul li {
  color: white;
  font-size: 1.5rem;
  margin-left: 9px;
}
.botones-prop ul li span{
  border-radius: 12px;
  font-size: 23px;
  font-weight: 700;
  align-items: center;
  display: grid;
  cursor: pointer;
}
.botones-prop ul li i, .botones-prop ul li span {
  text-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.7);
}

.contenerdor-consulta-prop {
  position: absolute;
  bottom: 9px;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 33px;
}

.input-consul {
  min-width: 100%;
  display: flex;
  align-items: center;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
  inset 0px 1px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
  box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
}
.form-control:focus, .form-control:active{
  border-color: #27E9A0;
  box-shadow: none!important;
  outline: none!important;
}
.input-bus-real{
  border-radius: 20px 0px 0px 20px!important;
  height: 29px;
  width: 100%;
}
.btn {
  margin-left: 5px;
}

.moli-o {
  color: #333;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  background: #f9f9f9;
  height: 30px;
  box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
}

.moli-o:hover, .moli-o:active{
  background: #27E9A0;
  -webkit-box-shadow: inset -2px -1px 4px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: inset -2px -1px 4px 0px rgba(0,0,0,0.75);
  box-shadow: inset -2px -1px 4px 0px rgba(0,0,0,0.75);
  color: white!important;
}

.conteinerIconShare{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 240px;
  padding: 15px;
  box-sizing: border-box;
}

.conteinerIconShare h3, .conteinerIconShare p{
  margin: 0 auto 5px auto;
}
.conteinerIconShare h3{
  font-size: 1rem;
}
.conteinerIconShare p{
  font-size: .8rem;
}


