@font-face {
  font-family: "MYRIADAB";
  src: url(./fonts/MYRIADAB.TTF) format('truetype');
}

@font-face {
  font-family: "MYRIADAM";
  src: url(./fonts/MYRIADAM.TTF) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 , h2, h3, h4, h5, label{
  font-family: MYRIADAB;
}

p, input, select, textarea, button, li{
  font-family: MYRIADAM;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.conteainerDocument{
  padding: 10px;
  box-sizing: border-box;
  max-width: 900px;
  margin: 0 auto;
}

.textContentDoc{
  text-align: justify;
}

.textContentDoc strong {
  font-weight: bold;
}

.textContentDoc u {
  text-decoration: underline;
}

.form-canvas{
  width: 100%;
  border: 1px solid #000; 
  background-color: #f5f5f5; 
  border-radius: 20px;
}

.containerFirmas{
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 20px;
  text-align: center;
  align-content: center;
}
.imgCanvaFirma{
  width: 200px;
  border: 1px solid #333;
}
  /*** media query ****/
  @media (max-width: 760px) {
    .imgCanvaFirma{
      width: 150px;
    }
    .containerFirmas p{
      font-size: .8em;
    }
  }

  @media (max-width: 500px) {
    .imgCanvaFirma{
      width: 110px;
    }
  }
