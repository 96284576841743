.pending-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fffde7;
    color: #ff9800;
    text-align: center;
  }
  
  .pending-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .pending-container p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .pending-container .button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #ff9800;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .pending-container .button:hover {
    background-color: #fb8c00;
  }
  