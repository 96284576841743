.cont-into{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    background: #012238;    
}
.cont-into h2{
    position: relative;
    font-size: 14vw;
    color: #012238;
    -webkit-text-stroke: 0.3vw #BFBFBF;
}
.cont-into h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: #BFBFBF;
    text-shadow: 5px 5px 5px white;
    -webkit-text-stroke: 0vw #383d52;
    border-right: 2px solid #BFBFBF;
    overflow: hidden;
    animation: animate 6s linear infinite;
}
@keyframes animate{
    0%,10%,100%{
        width: 0;
    }
    70%,90%{
        width: 100%;
    }
}

.contaner-idea{
    max-width: 900px;
    padding: 10px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}
.btn-cantidad{
    background-color: #27E9A0;;
}