.vp-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}

.vp-main-content {
  margin-left: 300px; /* Ajuste para el ancho del MenuAlto */
  transition: margin-left 0.3s ease; /* Animar el cambio de margen en pantallas pequeñas */
}

@media (max-width: 920px) {
  .vp-main-content {
    margin-left: 0; /* No hay margen cuando el MenuAlto está en pantalla completa en pantallas pequeñas */
  }
}
.loginRealEstateContainer{
    border: 1px solid #333;
    box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.1);
    border-radius: 6px;
    position: relative;
    width: 250px;
    margin: 0 auto;
 }
 .conLog{
  color: #fff;
 }

  .seccionFiltros{
    display: grid;
    text-align: center;
    align-items: center;
    max-width: 100%;
    min-height: 96vh;
    background-color: #012238;
    padding: 9px;
    box-sizing: border-box;
  }
  .seccionFiltros .titulosReal{
    margin-top: 25px;
    color: #fff;
    text-shadow: 1px 1px #000;
  }
  .titulosReal h1{
    font-size: 1.2em;
    margin-top: 0;
  }
  .seccionFiltros ul{
    display: flex;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    width: 250px;
  }
  .seccionFiltros ul a{
    text-decoration: none;
  }
  .seccionFiltros ul li{
    color: white;
    border: 1px solid white;
    height: 75px;
    width: 125px;
    margin: 0;
    display: grid;
    align-items: center;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
  }
  .seccionFiltros ul li:hover{
    background: #27E9A0;
    color: white;
  }
  #btnAlq{
    border-radius: 8px 0px 0px 8px;
  }
  #btnComprasR{
    border-radius: 0px 8px 8px 0px;
  }
  .contendorVideo {
    width: 90%;
    margin: 10px auto;
    position: relative;
    padding-top: 56.25%; /* Relación de aspecto 16:9 */
    overflow: hidden;
  }
  .contendorVideo iframe{
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 660px;
  }
  .avisoLegal{
    padding: 6px;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin: 20px auto;
    border: 1px solid #fff;
    color: #fff;
    max-width: 450px;
    border-radius: 6px;
  }
  .avisoLegal p{
    font-size: .9rem;
    text-align: justify;
  }
  .avisoLegal a{
    text-decoration: none;
  }
  .avisoLegal h3{
    font-size: 1.1rem;
    color: #333;
    text-shadow: none;
    margin: 0;
  }

  /*** filtered properties **/
  .property-list-container{
    padding: 10px;
    box-sizing: border-box;
  }