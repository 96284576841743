.property-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .contenedor-propiedad {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .carousel-item {
    min-width: 100%;
    transition: transform 0.5s ease;
  }
  
  .carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    cursor: pointer;
  }
  
  .title-property h1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .detalles-booleanos {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style-type: none;
  }
  
  .detalle-item {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .lista-detalles{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .lista-detalles .form-control {
    flex: 1 1 48%; /* Dos elementos por fila */
    box-sizing: border-box;
  }
  .lista-detalles .form-control i{
    margin-right: 10px;
  }
  .descripcion-propiedad h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .descripcion-propiedad p{
    text-align: justify;
  }
  
  .refiere-la-prop{
    width: 100%;
    background-color: #f1f1f1;
    color: #414141;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 auto;
    text-align: center;
  }
  .texto-r-prop{
    padding: 6px;
    box-sizing: border-box;
}
.iconos-refs-prop{
  height: 90px;
  width: 320px;
  background-color: white;
  border-radius: 6px;
  margin: 0 auto;
  display: grid;
  align-items: center;
  text-align: center;
  padding-left: 14px;
  box-sizing: border-box;
  -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
                      inset 0px 1px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
                      inset 0px 1px 3px rgba(0,0,0,0.3);
  box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
                      inset 0px 1px 3px rgba(0,0,0,0.3);
}
  .input-group {
    display: flex;
    gap: 10px;
  }
  
  input {
    flex-grow: 1;
  }
  
  .contenerdor-consulta-prop-ind{
     margin-bottom: 55px;
  }

.map-container {
  margin: 20px 0;
}

  