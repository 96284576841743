.conteiner-segmentos {
    width: 100%;
    max-width: 700px;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  
  .conteiner-segmentos ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    justify-content: center; 
  }
  
  .conteiner-segmentos li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 25%;
    margin: 10px 0; 
    font-size: .7rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    outline: none;
  }
  
  .conteiner-segmentos li .img-seg img:hover, .conteiner-segmentos li .img-seg img:focus {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(39, 233, 160, 0.7);
  }
  
  .img-seg img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #27E9A0;
    background-color: #d1d9e6;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .conteiner-segmentos li div {
    margin-top: 10px;
  }
  
  @media (max-width: 430px) {
    .img-seg img{
      width: 60px;
      height: 60px;
    }
    .conteiner-segmentos li {
      font-size: .6;
    }
  }

  @media (max-width: 310px) {
    .img-seg img{
      width: 50px;
      height: 50px;
    }
  }