.office-container {
    max-width: 800px;
    margin: 0px auto 55px auto;
    font-family: Arial, sans-serif;
  }
  
  .role-selection h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .btn-role {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background-color: #27E9A0;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn-role:hover {
    background-color: #1ba36f;
  }
  
  .propietario-section h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .search-input-mis-prop{
    border-radius: 20px;
    height: 20px;
    padding: 5px 5px 5px 10px;
    width: 100%;
    max-width: 300px;
    border: .5px solid #d1d9e6;
    box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
    margin: 0 auto;
  }
  .property-card {
    padding: 5px;
    margin: 10px 0;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
  }
  
  .property-card h3 {
    margin: 5px;
    font-size: 1rem;
  }
  
  .property-card p {
    margin: 5px;
  }
  
  .property-card button {
    padding: 8px 12px;
    background-color: #27E9A0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .property-card button:hover {
    background-color: #218838;
  }
  
  .add-property {
    margin-top: 20px;
    text-align: center;
  }
  
  .btn-wsp {
    padding: 10px 20px;
    background-color: #25d366;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
  }
  
  .btn-wsp:hover {
    background-color: #128c7e;
  }

  /***** mis reservas y consultas ***/
  .containerMisReservas{
    padding: 10px;
    box-sizing: border-box;
  }

  .role-selection {
    text-align: center;
  }
  
  .role-selection h2 {
    margin-bottom: 20px;
  }
  
  .role-selection .btn {
    margin: 10px;
  }
    
  .office-container h2, .office-container h3 {
    margin-bottom: 10px;
  }

  /**** OFFICE ASESOR ***********/
  .section-container{
    width: 100%;
    height: auto;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    position: relative;
    margin: 0 auto 25px auto;
    background-color: #f1f1f1;
    padding: 10px;
    box-sizing: border-box;
    align-content: center;
  }
  .mensajeEspera{
    padding: 10px;
    box-sizing: border-box;
    margin: 50px auto;
  }
  .section-container .section{
    height: 60px;
    box-sizing: border-box;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    background-color: white;
    margin: 0 auto;
    width: 120px;
    border-radius: 15px;
    display: grid;
    align-items: center;
    text-align: center;
    position: relative;
    margin: 5px auto;
  }

  .section-container .section:hover{
    color: white;
    background: #012238;   
  }

  .section-container .section h3{
    margin: 0;
    font-size: 1em;
  }
  .client-table{
    padding: 10px;
    box-sizing: border-box;
  }
  .filaIconsTable{
    display: inline-flex;
    flex-wrap: wrap;
  }
  .celdaLarga{
    overflow-x: auto;
    max-width: 200px;
  }
  .filaIconsTable i{
    margin: 3px;
    display: grid;
    align-items: center;
    height: 20px;
    width: 15px;
    text-align: center;
  }

  .filaIconsTable .fa-eye {
    position: relative;
    left: 0;
    bottom: 0;
    color: #333;
    font-weight: 600;
    text-shadow: none;
  }

  .add-property input, .add-property select{
    border-radius: 20px;
  }
  .sectionPropOfice, .document-creator{
    padding: 10px;
    box-sizing: border-box;
  }

  /************ documentos ************/
  .document-item {
    border: 1px solid #ddd;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .document-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .document-header:hover {
    background-color: #f0f0f0;
  }
  
  .document-content {
    padding: 10px;
    border-top: 1px solid #ddd;
    margin-top: 10px;
  }
  .listaDocumentos{
    list-style: none;
  }
  .liDoc{
    padding: 9px 5px 3px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    height: 35px; 
    margin-bottom: 5px;   
    background-color: #f1f1f1;
    position: relative;
  }
  .liDoc h4{
    margin: 0px;
  }
  .liExp{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    max-width: 450px;
    min-height: 350px;
    max-height: 70vh;
    overflow-y: auto;
  }
  .liClientesSelected{
    list-style: none;
  }
  /*** config ****/
  .inputGroupCheckox{
    display: inline-flex;
    flex-wrap:wrap;
    position: relative;
    margin: 5px auto;
  }
  .inputGroupCheckox .inputCa{
    margin: 10px;
    max-width: 120px;
  }
  /*** media query ****/
  @media (max-width: 560px) {
    .section-container .section h3{
      font-size: .85em;
    }
  }

  @media (max-width: 480px) {
    .section-container .section h3{
      font-size: .75em;
    }
  }
  @media (max-width: 420px) {
    .section-container .section{
      width: 90px;
    }
    .section-container .section h3{
      font-size: .65em;
    }
  }

  