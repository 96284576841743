@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Menú alto */
.menu-alto-container {
    display: flex;
    flex-direction: column;
  }
  
  .menu-alto {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
    background-color: #fff;
    border-right: 1px solid #CCD1D9;
    box-shadow: 3px 3px 0 0 rgba(0,0,0,0.1);
    transform: translateX(0); /* Siempre visible en pantallas grandes */
    transition: transform 0.3s ease;
    z-index: 1000;
    overflow-y: auto;
    padding-bottom: 45px;
    box-sizing: border-box;
  }
  
  .menu-toggle {
    display: none; /* Ocultar el icono en pantallas grandes */
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
  }
  
  @media (max-width: 920px) {
    .menu-alto.hide {
      transform: translateX(-100%); /* Oculto cuando se cierra en pantallas grandes */
    }
    .menu-alto {
      width: 100%; /* Menú a ancho completo en pantallas pequeñas */
    }
  
    .menu-toggle {
      display: block;
    }
  }
  
  .menu-alto .item-menu,
  .menu-alto .widget ul a li,
  .menu-alto .widget ul a li:hover {
    padding: 5px!important;
    width: 100%;
  }
  
  .menu-alto .item-menu:hover,
  .menu-alto .widget ul a li:hover,
  .menu-alto #iconContenido {
    background: #3D5056;
    border-radius: 22px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
  }
  
  .menu-alto .widget {
    padding: 10px;
    background-color: #F5F7FA;
    border-bottom: 1px solid #E6E9ED;
  }
  
  .menu-alto .widget h5,
  .menu-alto .widget h3 {
    font-size: 16px;
    margin: 5px;
  }
  
  .menu-alto .widget ul {
    list-style: none;
    padding: 0;
    margin: 0 ;
  }
  .widgetImg{
    text-align: center;
  }
  .menu-alto .widget p{
    margin: 0;
  }
  .menu-alto .widget ul li {
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;

  }
  .menu-alto .widget ul a{
    color: #333;
    text-decoration: none;
  }
  
 .follow ul {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }
  
  .follow ul li a {
    font-size: 20px;
    color: #333;
    text-decoration: none;
  }
  
  .menu-alto .logo-menu-reservos {
    width: 60px;
    margin: 20px;
  }
  

.menu-regresar {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #f1f1f1;
    min-width: 100%;
    height: 45px;
    text-align: center;
    z-index: 6000;
}
.menu-regresar a, .menu-regresar a:hover {
    color: #414141;
}
.menu-regresar ul {
    list-style: none;
    display: flex;
    margin: 5px auto;
    padding: 0;
    box-sizing: border-box;
}
.menu-regresar ul li {
    margin: 0px auto;
    box-sizing: border-box;   
    color: #333;
    display: grid;
}
.simenu{
  font-size: 1.55em;
  margin-top: 4px!important;
}
.memenu{
  font-size: 1em;
}
.item-menu2 i {
  color: #333; /* Color del icono */
}
  
.item-menu2.login i {
    color: #464646; /* Color del icono si el usuario no está autenticado */
}

.menu-regresar .menu-toggle-bajo {
    display: none;
  }
  
  @media (max-width: 920px) {
    .menu-regresar .menu-toggle-bajo {
      display: block;
    }
}