.account-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.account-page h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #343a40;
}

.account-form .form-group {
    margin-bottom: 15px;
}

.account-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #495057;
}

.account-form input {
    width: 100%;
    max-width: 580px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 16px;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
}

.account-form .btn-update {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #27E9A0;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.account-form .btn-update:hover {
    background-color: #f8f9fa;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
}

.account-form small {
    display: block;
    margin-top: 5px;
    color: #6c757d;
}
