/* AdminProperties.css */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  img.thumbnail {
    width: 50px;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    list-style: none;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    color: #007bff;
    text-decoration: none;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .page-item.active .page-link {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
  }

  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    box-sizing: border-box;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
  }
  
  button {
    margin: 5px;
  }
  
/********* Editar propiedades form ***********/
.container-edite-form{
  background-color: #f1f1f1;
  padding: 20px 10px;
  box-sizing: border-box;
  max-height: 80vh;
  max-width: 900px;
  margin: 20px auto;
  overflow-y: auto;
  top: 20px;
}
.file-preview{
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
}  

.remove-file-button {
  position: absolute;
  top: 0px; /* Ajusta según sea necesario */
  right: 0px; /* Ajusta según sea necesario */
  background: transparent;
  border: none;
  color: red; /* Color de la "X" */
  font-size: 20px; /* Tamaño de la "X" */
  cursor: pointer;
  margin: 0;
}

.file-preview img{
  max-width: 150px;
  margin: 5px;
}
.inputText{
  display: inline-flex;
  flex-wrap: wrap;
}
.inputText .form-group{
  max-width: 250px;
  margin: 5px;
  
}
.texareaedite{
  min-height: 250px;
}
