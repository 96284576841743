.parteIdea{
    margin-bottom: 50px;
}
.cont-into{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    background: #012238;    
}
.cont-into h2{
    position: relative;
    font-size: 14vw;
    color: #012238;
    -webkit-text-stroke: 0.3vw #BFBFBF;
}
.cont-into h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: #BFBFBF;
    text-shadow: 5px 5px 5px white;
    -webkit-text-stroke: 0vw #383d52;
    border-right: 2px solid #BFBFBF;
    overflow: hidden;
    animation: animate 6s linear infinite;
}
@keyframes animate{
    0%,10%,100%{
        width: 0;
    }
    70%,90%{
        width: 100%;
    }
}
.container-idea {
    max-width: 600px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    box-sizing: border-box;
}
.formbuss{
    max-width: 600px;
    margin: 0 auto;
}
.mb-3 {
    margin-bottom: 1rem;
  }
    
  .form-control {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #ccc;
    min-height: 35px;
    -webkit-box-shadow:0px 2px 2px rgba(255,255,255,0.25),
    inset 0px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:   0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    box-shadow:        0px 2px 2px rgba(255,255,255,0.25),
        inset 0px 1px 3px rgba(0,0,0,0.3);
    margin-top: 5px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .btn_cantidad {
    background-color: #27E9A0;;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn_cantidad:hover {
    background-color: #f8d7da;
  }
  
  .alert_moli_exito {
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
  }
  
  .alert_moli_error {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
  }