.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    padding: 10px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    color: #444;
  }

  .close-button:hover{
    color: #444;
    background-color: transparent;
  }
  
  .boolean-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .boolean-item {
    background: #444;
    color: white;
    padding: 10px;
    border-radius: 4px;
  }
  