.somos-prof{
    display: grid;
    align-items: center;
    text-align: center;
    height: 90vh;
    background-color: #012238;
    padding: 25px;
    box-sizing: border-box;
  }
  .somos-prof h1{
    font-size: 3rem;
    color: #BFBFBF;
  }
  .somos-prof p{
    color: white;
    font-size: 5vw;
  }
  .crece-en-clientes{
    display: grid;
    height: 100vh;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
  }
  .crece-en-clientes .img-crece-prof{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 0 auto;
  }
  .crece-en-clientes h2{
    font-size: 8vw;
  }
  .beneficios-prof{
    display: flex;
    position: relative;
    height: 50vh;
  } 
  .img-a{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
  .img-b{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }
  .texto-prof-beneficio{
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: grid;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    background: #3D5056;
  
    color: white;
  }
  .texto-prof-apoyo{
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: grid;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    background: #3D5056;
  
    color: white;
  }
 
  .title-valores{
    margin: 20px auto;
    text-align: center;
  }
  .valores-prof{
    display: flex;
    flex-wrap: wrap;
  }
  .val1 , .val2 , .val3{
    max-width: 350px;
    height: 260px;
    margin: 10px auto;
    border-radius: 10px;
    display: grid;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.1);
    background-color: #FFFEFF;
  }

  .sumarMiInm a{
    max-width: 250px;
    margin: 0 auto;
    background-color: #27E9A0;
    color: #fff;
    padding: 15px 30px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  .sumarMiInm a:hover {
    background-color: #20c589;
    color: #fff;
  }

  .sumate-a-la-red{
    max-width: 300px;
    margin: 10px auto 55px auto;
    text-align: center;
  }