.categorias-container {
  padding: 10px;
}

.categoria {
  margin-bottom: 30px;
}

.categoria h2 {
  font-size: 1.25em;
}

.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.properties-slider {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.property-card-c {
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-right: 5px;
  min-width: 220px;
}

.property-card-c:hover {
  transform: scale(1.05);
}

.property-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}


.property-info h3 {
  font-size: 1em;
  color: #333;
}

.property-info p {
  font-size: 0.8em;
  color: #555;
}

.property-info h3,
.property-info p,
.property-info button {
  margin: 0;
}

.property-info button {
  width: 100%;
  color: white;
  border: none;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  padding: 9px;
  cursor: pointer;
  z-index: 1;
}

.slider-button.left {
  left: 0;
}

.slider-button.right {
  right: 0;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .property-card-c {
    min-width: 150px;
    max-width: 200px;
  }

  .property-image {
    height: 120px;
  }
}
