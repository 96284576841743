.failure-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff3f3;
    color: #f44336;
    text-align: center;
  }
  
  .failure-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .failure-container p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .failure-container .button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #f44336;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .failure-container .button:hover {
    background-color: #e53935;
  }
  