.carousel-promos{
    margin: 25px auto;
    padding: 5px;
    box-sizing: border-box;
  }
  /* General Styles for the Slider */
  .swiper-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
  }
  
  /* Wrapper */
  .swiper-wrapper {
    display: flex;
  }
  
  /* Styles for the Slides */
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }
  
  /* Styles for the Images */
  .swiper-slide img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .swiper-slide img:hover {
    transform: scale(1.05);
  }
  
  /* Text Styles */
  .texto-carousel-promo {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 10px;
    text-align: left;
    width: 80%;
  }
  
  .texto-carousel-promo h5 {
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .texto-carousel-promo p {
    font-size: 1.2rem;
  }
  
  .texto-carousel-promo button {
    background-color: #27E9A0;
    border: none;
    color: white;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .texto-carousel-promo button:hover {
    background-color: #1bb382;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .texto-carousel-promo h5 {
        font-size: 1.5rem;
    }
  
    .texto-carousel-promo p {
        font-size: 1rem;
    }
  
    .texto-carousel-promo button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
  }
  
  @media (max-width: 480px) {
    .texto-carousel-promo h5 {
        font-size: 1.2rem;
    }
  
    .texto-carousel-promo p {
        font-size: 0.9rem;
    }
  
    .texto-carousel-promo button {
        font-size: 0.8rem;
        padding: 7px 10px;
    }
  }
  